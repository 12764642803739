import { PlusCircleOutlined } from '@ant-design/icons'
import { Button, Modal } from 'antd'
import { convertAddress } from 'helpers'
import { useEffect, useState } from 'react'
import { customerService } from 'services'
import styled from 'styled-components'
import { EditOutlined } from '@ant-design/icons'

const ModalBody = styled.div`
  padding-right: 10px;
  max-height: 400px;
  overflow-y: scroll;
`

const ModalChooseAddress = ({ type, open, onCancel, onChooseAddress, onAddAddress, refreshData }) => {
  const [addressList, setAddressList] = useState<any[]>([])
  const [addressListFilter, setAddressListFilter] = useState<any[]>([])
  const [searchName, setSearchName] = useState<string>()
  const [searchPhone, setSearchPhone] = useState<string>()
  const modalTitle = type === 'delivery' ? 'Thông tin gửi hàng' : 'Thông tin nhận hàng'

  const handleAdd = () => {
    onAddAddress(undefined)
    onCancel()
  }

  useEffect(() => {
    customerService.getListAddress(type, (res) => setAddressList(res?.addresses))
  }, [type, refreshData])

  useEffect(() => {
    if (addressList) {
      let filteredList = [...addressList]

      if (searchName) {
        filteredList = filteredList.filter((item) => item?.full_name?.toLowerCase()?.includes(searchName.toLowerCase()))
      }

      if (searchPhone) {
        filteredList = filteredList.filter((item) => item?.phone_number?.includes(searchPhone))
      }

      setAddressListFilter(filteredList)
    }
  }, [addressList, searchName, searchPhone])

  return (
    <Modal
      title={<p style={{ fontSize: '20px', fontWeight: 700 }}>{modalTitle}</p>}
      open={open}
      footer={false}
      centered
      onCancel={onCancel}
      width={700}
    >
      <ModalBody>
        <TableWrapper>
          <tr>
            <th style={{ padding: 6 }}>
              <input value={searchName} onChange={(e) => setSearchName(e?.target?.value)} type='text' placeholder='Tên' />
            </th>
            <th style={{ padding: 6, width: 100 }}>
              <input
                value={searchPhone}
                onChange={(e) => setSearchPhone(e?.target?.value)}
                type='text'
                placeholder='Điện thoại'
              />
            </th>
            <th style={{ width: '60%', minWidth: 220 }}>Địa chỉ</th>
          </tr>
          <tr>
            <td colSpan={4} style={{ textAlign: 'center' }}>
              <Button onClick={handleAdd}>
                <PlusCircleOutlined /> Thêm mới
              </Button>
            </td>
          </tr>
          {addressListFilter?.map((address) => (
            <tr key={address?.id} onClick={() => onChooseAddress(type, address)} style={{ cursor: 'pointer' }}>
              <td>{address?.full_name}</td>
              <td>{address?.phone_number}</td>
              <td>
                {address?.address} {convertAddress(address, true)}
              </td>
            </tr>
          ))}
        </TableWrapper>
      </ModalBody>
    </Modal>
  )
}

export default ModalChooseAddress

const TableWrapper = styled.table`
  width: 100%;
  height: 100%;
  border: 1px solid #ccc;

  th {
    text-align: center;
    border: 1px solid #ccc;

    input {
      border: 1px solid #bfb8b8;
      outline: none;
      border-radius: 5px;
      font-weight: bold;
      padding: 2px 4px;
      font-size: 13px;
    }
  }

  td {
    vertical-align: baseline;
    border: 1px solid #ccc;
    padding: 4px;
    font-size: 13px;
  }

  .action-table {
    button {
      background: transparent;
      border: none;
      cursor: pointer;
      svg {
        font-size: 18px;
      }
    }
  }
`
