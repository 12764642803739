import 'assets/css/index.css'
import { getAuthToken } from 'helpers/FetchHelper'
import PrivateLayout from 'layouts/PrivateLayout'
import PublicLayout from 'layouts/PublicLayout'
import PrintLayout from 'layouts/PrintLayout'
import BlankLayout from 'layouts/BlankLayout'
import { Suspense, useEffect } from 'react'
import { BrowserRouter, Redirect, Route, Switch, useLocation } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import useGetProvince from 'state/location/hooks/useGetLocation'
import useGetBankData from 'state/bank/hooks/useGetBankData'
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import ActiveAccount from './active-account'
import Dashboard from './bang-dieu-khien'
import BaoCapTaiChinh from './bao-cao-tai-chinh'
import LichSuDieuChinhKhoiLuong from './lich-su-dieu-chinh-khoi-luong'
import DonDieuChinhKhoiLuong from './don-dieu-chinh-khoi-luong'
import HoTroKhachHang from './ho-tro-khach-hang'
import XemHoTroKhachHang from './ho-tro-khach-hang/[id]'
import LichSuDonNhan from './lich-su-don-van'
import LichSuGiaoDich from './lich-su-giao-dich'
import LichSuKyQuy from './lich-su-ky-quy'
import LichSuDoiSoat from './lich-su-doi-soat'
import Login from './login/Index'
import Profile from './profile'
import ResetPassword from './reset-password'
import ThoeDoiDonVan from './theo-doi-don-van'
import TimkiemDonVan from './tim-kiem-don-van'
import YeuCauCapNhap from './yeu-cau-cap-nhat'
import OrderCreate from './tao-don-hang'
import HotLoginUser from './hot-login-user'
import OrderView from './xem-don-hang/[id]'
import OrderPrint from './in-don-hang/[id]'
import OrderPrintPublic from './in-don-hang-public/[id]'
import Logger from './logger'
import AboutUs from './ve-chung-toi'
import Service from './dich-vu'
import Recruitment from './tuyen-dung'
import News from './tin-tuc'
import NewsDetail from './chi-tiet/[slug]'
import TraCuuDonVan from './tra-cuu-don-van'
import { useMetaTags } from 'react-metatags-hook'
import { CONFIG_SEO } from 'constants/SEO'

export const isLogin = () => {
  const auth = getAuthToken()

  return Boolean(auth)
}

const useConditionalMetaTags = () => {
  const location = useLocation()
  const currentPath = location.pathname

  useMetaTags(
    {
      title: CONFIG_SEO?.find((item) => item?.path === currentPath)?.name,
    },
    []
  )
}

export const PublicRoute = ({ component: Component, ...rest }) => {
  useConditionalMetaTags()

  return (
    <Route
      {...rest}
      render={(props) => (
        <PublicLayout {...rest}>
          <Component {...props} />
        </PublicLayout>
      )}
    />
  )
}

export const PublicRouteNoneMeta = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <PublicLayout {...rest}>
          <Component {...props} />
        </PublicLayout>
      )}
    />
  )
}

export const BlankRoute = ({ component: Component, ...rest }) => {
  useConditionalMetaTags()

  return (
    <Route
      {...rest}
      render={(props) => (
        <BlankLayout {...rest}>
          <Component {...props} />
        </BlankLayout>
      )}
    />
  )
}
export const PrivateRoute = ({ component: Component, ...rest }) => {
  const { fetchProvince } = useGetProvince()
  const { fetchBankData } = useGetBankData()
  useConditionalMetaTags()

  useEffect(() => {
    if (!isLogin()) {
      toast.error('Bạn phải đăng nhập mới vào được trang này!')
    }
  }, [])

  useEffect(() => {
    Promise.all([fetchProvince(), fetchBankData()])
  }, [fetchProvince, fetchBankData])

  return (
    <Route
      {...rest}
      render={(props) =>
        isLogin() ? (
          <PrivateLayout {...rest}>
            <Component {...props} />
          </PrivateLayout>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  )
}

export const PrintRoute = ({ component: Component, ...rest }) => {
  const { fetchProvince } = useGetProvince()
  const { fetchBankData } = useGetBankData()

  useEffect(() => {
    if (!isLogin()) {
      toast.error('Bạn phải đăng nhập mới vào được trang này!')
    }
  }, [])

  useEffect(() => {
    Promise.all([fetchProvince(), fetchBankData()])
  }, [fetchProvince, fetchBankData])

  return (
    <Route
      {...rest}
      render={(props) =>
        isLogin() ? (
          <PrintLayout {...rest}>
            <Component {...props} />
          </PrintLayout>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  )
}

export const PrintRoutePublic = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <PrintLayout {...rest}>
          <Component {...props} />
        </PrintLayout>
      )}
    />
  )
}

function App() {
  return (
    <Suspense fallback={null}>
      <BrowserRouter>
        <Switch>
          <PublicRoute exact path='/' component={Login} />
          <PublicRoute exact path='/active-account' component={ActiveAccount} />
          <PublicRoute exact path='/reset-password' component={ResetPassword} />
          <PublicRoute exact path='/hot-login-user' component={HotLoginUser} />
          <PublicRoute exact path='/logger' component={Logger} />
          <PublicRoute exact path='/ve-chung-toi' component={AboutUs} />
          <PublicRoute exact path='/dich-vu' component={Service} />
          <PublicRoute exact path='/tuyen-dung' component={Recruitment} />
          <PublicRoute exact path='/tin-tuc' component={News} />
          <PublicRouteNoneMeta exact path='/chi-tiet/:slug' component={NewsDetail} />
          <PublicRoute exact path='/tra-cuu-don-van' component={TraCuuDonVan} />

          <PrivateRoute exact path='/bang-dieu-khien' component={Dashboard} />
          <PrivateRoute exact path='/theo-doi-don-van' component={ThoeDoiDonVan} />
          <PrivateRoute exact path='/lich-su-dieu-chinh-khoi-luong' component={LichSuDieuChinhKhoiLuong} />
          <PrivateRoute exact path='/don-dieu-chinh-khoi-luong' component={DonDieuChinhKhoiLuong} />
          <PrivateRoute exact path='/yeu-cau-cap-nhat' component={YeuCauCapNhap} />
          <PrivateRoute exact path='/ho-tro-khach-hang' component={HoTroKhachHang} />
          <PrivateRoute exact path='/xem-ho-tro/:id' component={XemHoTroKhachHang} />
          <PrivateRoute exact path='/lich-su-don-van' component={LichSuDonNhan} />
          <PrivateRoute exact path='/bao-cao-tai-chinh' component={BaoCapTaiChinh} />
          <PrivateRoute exact path='/lich-su-ky-quy' component={LichSuKyQuy} />
          <PrivateRoute exact path='/lich-su-doi-soat' component={LichSuDoiSoat} />
          <PrivateRoute exact path='/lich-su-giao-dich' component={LichSuGiaoDich} />
          <PrivateRoute exact path='/tai-khoan' component={Profile} />
          <PrivateRoute exact path='/tao-don-hang' component={OrderCreate} />
          <PrivateRoute exact path='/xem-don-hang/:id' component={OrderView} />
          <PrivateRoute exact path='/tim-kiem-don-van' component={TimkiemDonVan} />

          <PrintRoute exact path='/in-don-hang/:id' component={OrderPrint} />

          <PrintRoutePublic exact path='/in-don/:id' component={OrderPrintPublic} />

          <Route path='*' component={() => <Redirect to='/' />} />
        </Switch>
      </BrowserRouter>
      <ToastContainer autoClose={5000} />
    </Suspense>
  )
}

export default App
