import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import ModalAddress from 'components/Modal/ModalAddress'
import ModalDelete from 'components/Modal/ModalDelete'
import TableBase from 'components/Table/TableBase'
import { convertAddress } from 'helpers'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { customerService } from 'services'
import styled from 'styled-components'
import ActionGroup from './ActionGroup'
import { Input } from 'antd'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  .ant-table thead tr th {
    background: #fff;
    color: rgba(0, 0, 0, 0.85);
  }
`

const OptionAction = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
  justify-content: center;

  svg {
    cursor: pointer;
  }
`

type TDeliveryTable = {
  type: string
}

const DeliveryTable = ({ type }: TDeliveryTable) => {
  const [dataList, setDataList] = useState<any>([])
  const [dataListFilter, setDataListFilter] = useState<any>([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalDelete, setIsModalDelete] = useState(false)
  const [editData, setEditData] = useState<any>(undefined)
  const [loading, setLoading] = useState(false)

  const [searchName, setSearchName] = useState<string>()
  const [searchPhone, setSearchPhone] = useState<string>()

  useEffect(() => {
    if (dataList) {
      let filteredList = [...dataList]

      if (searchName) {
        filteredList = filteredList.filter((item) => item?.full_name?.toLowerCase()?.includes(searchName.toLowerCase()))
      }

      if (searchPhone) {
        filteredList = filteredList.filter((item) => item?.phone_number?.includes(searchPhone))
      }

      setDataListFilter(filteredList)
    }
  }, [dataList, searchName, searchPhone])

  const handleShowModal = () => {
    setIsModalOpen((prev) => !prev)
  }

  const handleShowModalDelete = () => {
    setIsModalDelete((prev) => !prev)
  }

  const handleCloseModal = () => {
    if (editData) setEditData(undefined)
    if (isModalOpen) handleShowModal()
    if (isModalDelete) handleShowModalDelete()
  }

  const handleEdit = (item, modalType) => {
    setEditData(item)
    if (modalType === 'edit') handleShowModal()
    if (modalType === 'delete') handleShowModalDelete()
  }

  const handleSubmitDelete = async () => {
    if (editData) {
      setLoading(true)

      try {
        await customerService.deleteAddress(
          editData?.id,
          async (response) => {
            toast.success(response?.data?.message, {
              autoClose: 1000,
            })
            setLoading(false)
            await customerService.getListAddress(type, (res) => setDataList(res?.addresses))
            handleCloseModal()
          },
          (response) => {
            toast.error(response?.message || 'Xóa dữ liệu không thành công')
            setLoading(false)
          }
        )
      } catch (error) {
        setLoading(false)
        console.error('deleteAddress', error)
      }
    }
  }

  const columns = [
    {
      title: <Input type='text' placeholder='Tên' value={searchName} onChange={(e) => setSearchName(e?.target?.value)} />,
      dataIndex: 'full_name',
      render: (value) => value,
    },
    {
      title: (
        <Input type='text' placeholder='Điện thoại' value={searchPhone} onChange={(e) => setSearchPhone(e?.target?.value)} />
      ),
      dataIndex: 'phone_number',
      width: '15%',
      render: (value) => value,
    },
    {
      title: 'Địa chỉ',
      dataIndex: 'address',
      width: '45%',
      render: (_, record) => convertAddress(record),
    },
    {
      title: 'Thao tác',
      dataIndex: '',
      width: '15%',
      render: (_, record) => (
        <OptionAction>
          <EditOutlined onClick={() => handleEdit(record, 'edit')} />
          <DeleteOutlined onClick={() => handleEdit(record, 'delete')} />
        </OptionAction>
      ),
    },
  ]

  useEffect(() => {
    customerService.getListAddress(type, (res) => setDataList(res?.addresses))
  }, [type])

  return (
    <>
      <Wrapper>
        <ActionGroup onAdd={handleShowModal} />
        <TableBase
          columns={columns}
          dataSource={dataListFilter}
          pagination={false}
          scroll={{ x: 800 }}
          rowKey={(row) => row.id}
        />
      </Wrapper>

      <ModalAddress open={isModalOpen} onCancel={handleCloseModal} type={type} editData={editData} refreshData={setDataList} />
      <ModalDelete
        open={isModalDelete}
        onCancel={handleCloseModal}
        onSubmit={handleSubmitDelete}
        loading={loading}
        title='Xóa địa chỉ'
      />
    </>
  )
}

export default DeliveryTable
