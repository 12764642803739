import { Button, Col, Row, Skeleton } from 'antd'
import FormWrapper from 'components/Form/FormWrapper'
import { useCallback, useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { orderService } from 'services'
import useGetCustomerData from 'state/customer/hooks/useGetCustomerData'
import styled from 'styled-components'
import InfoUser from './components/InfoUser'
import { numberWithCommas } from 'utils'
import { toast } from 'react-toastify'
import ModalUpdateOrder from './components/ModalUpdateOrder'
import {
  RollbackOutlined,
  CloudDownloadOutlined,
  RetweetOutlined,
  EnvironmentOutlined,
  EnvironmentFilled,
  SearchOutlined,
  BugFilled,
} from '@ant-design/icons'
import ModalCancelOrder from './components/ModalCancelOrder'
import ROUTER from 'services/router'
import ModalCallSupport from './components/ModalCallSupport'
import ModalUpdateStatus from './components/ModalUpdateStatus'

const WrapperPage = styled.div`
  min-height: 77vh;
  width: 100%;
  padding: 0 20px 40px;

  .box-info {
    display: flex;
    gap: 12px;

    p {
      font-size: 13px;

      ${({ theme }) => theme.mediaQueries.sm} {
        font-size: 15px;
      }

      &:nth-child(1) {
        width: 85px;
        text-align: right;

        ${({ theme }) => theme.mediaQueries.sm} {
          width: 150px;
        }
      }

      &:nth-child(2) {
        font-weight: bold;
        width: calc(100% - 85px);

        ${({ theme }) => theme.mediaQueries.sm} {
          width: calc(100% - 150px);
        }
      }
    }

    button {
      display: flex;
      align-items: center;

      .anticon-cloud-download {
        margin-top: 4px;
      }

      p {
        margin-left: 4px;
      }

      .view-pc {
        display: none;

        ${({ theme }) => theme.mediaQueries.sm} {
          display: contents;
        }
      }
    }
  }
`

const ButtonGroups = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;

  ${({ theme }) => theme.mediaQueries.xs} {
    gap: 20px;
  }
`

const Page = () => {
  const history = useHistory()
  const { id } = useParams()
  const { customerProfile } = useGetCustomerData()

  const [dataDetail, setDataDetail] = useState<any>()
  const [loadingDataDetail, setLoadingDataDetail] = useState<boolean>(true)
  const [modalUpdateOrder, setModalUpdateOrder] = useState<boolean>(false)
  const [modalCancelOrder, setModalCancelOrder] = useState<boolean>(false)
  const [modalCallSupport, setModalCallSupport] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingCallSupport, setLoadingCallSupport] = useState<boolean>(false)
  const [loadingReOrder, setLoadingReOrder] = useState<boolean>(false)
  const [modalUpdateStatus, setModalUpdateStatus] = useState<{
    toggle: boolean
    type?: number
  }>({
    toggle: false,
  })
  const [loadingUpdateStatus, setLoadingUpdateStatus] = useState<boolean>(false)

  const getDetailOrder = useCallback(() => {
    if (id) {
      setLoadingDataDetail(true)
      orderService.getDetail(id, (data) => {
        setDataDetail(data?.data)
        setLoadingDataDetail(false)
      })
    }
  }, [id])

  useEffect(() => {
    getDetailOrder()
  }, [getDetailOrder])

  const handleReOrder = useCallback(() => {
    setLoadingReOrder(true)

    orderService.reOrder(
      id,
      (response) => {
        toast.success(response?.data?.message, {
          autoClose: 1000,
        })
        setLoadingReOrder(false)
        getDetailOrder()
      },
      (response) => {
        toast.error(response?.message)
        setLoadingReOrder(false)
      }
    )
  }, [getDetailOrder, id])

  return (
    <>
      <WrapperPage>
        <p className='title-page'>Chi tiết vận đơn</p>

        <Row gutter={[15, 10]}>
          {dataDetail?.is_fail && (
            <Col span={24}>
              <FormWrapper title='Thông báo lỗi khi tạo đơn' icon={<BugFilled />}>
                <div style={{ color: 'red' }}>{dataDetail?.note}</div>
              </FormWrapper>
            </Col>
          )}

          <Col span={24}>
            <FormWrapper title='Thông tin vận đơn' icon={<EnvironmentOutlined />}>
              {!loadingDataDetail ? (
                <>
                  <div className='box-info'>
                    <p>Người tạo đơn:</p>
                    <p>{customerProfile?.id}</p>
                  </div>
                  <div className='box-info'>
                    <p>Mã vận đơn:</p>
                    <p>
                      {dataDetail?.order_code}{' '}
                      {!dataDetail?.is_fail && (
                        <a
                          style={{ color: '#1890ff' }}
                          href={`https://viettelpost.vn/viettelpost-iframe/tra-cuu-hanh-trinh-don-hang-v3-recaptcha?order=${dataDetail?.order_code}`}
                          target='_Blank'
                          rel='noreferrer'
                        >
                          <SearchOutlined /> Xem lộ trình
                        </a>
                      )}
                    </p>
                  </div>
                  <div className='box-info'>
                    <p>Loại đơn vận:</p>
                    <p>{dataDetail?.detail?.package_title}</p>
                  </div>
                  <div className='box-info'>
                    <p>Thời gian tạo:</p>
                    <p>{dataDetail?.created_at}</p>
                  </div>
                  <div className='box-info'>
                    <p>Phí vận chuyển:</p>
                    <p>
                      {numberWithCommas(dataDetail?.fee)}đ ({dataDetail?.detail?.who === 0 ? 'người gửi' : 'người nhận'} trả)
                    </p>
                  </div>
                  <div className='box-info'>
                    <p>Tiền hàng:</p>
                    <p>{numberWithCommas(dataDetail?.detail?.price)}đ</p>
                  </div>
                  <div className='box-info'>
                    <p>Tiền thu hộ:</p>
                    <p>{numberWithCommas(dataDetail?.cod)}đ</p>
                  </div>
                  <div className='box-info'>
                    <p>Khai giá hàng hoá:</p>
                    <p>{numberWithCommas(dataDetail?.product_price)}đ</p>
                  </div>
                  <div className='box-info'>
                    <p>Phí bảo đảm:</p>
                    <p>{numberWithCommas(+dataDetail?.insurance)}đ</p>
                  </div>
                  <div className='box-info'>
                    <p>Trạng thái:</p>
                    <p style={{ color: dataDetail?.is_fail ? 'red' : '' }}>
                      {dataDetail?.is_fail
                        ? 'Tạo đơn hàng thất bại'
                        : dataDetail?.statusLogs?.find((item) => item?.status === dataDetail?.status)?.status_string}
                    </p>
                  </div>
                  <div className='box-info'>
                    <p>Ngày cập nhật:</p>
                    <p>{dataDetail?.updated_at}</p>
                  </div>
                  <div className='box-info' style={{ alignItems: 'center' }}>
                    <p>{dataDetail?.is_fail ? 'Tạo lại' : 'Tải'} đơn vận:</p>
                    <p>
                      {dataDetail?.is_fail ? (
                        <Button icon={<RetweetOutlined />} onClick={handleReOrder} loading={loadingReOrder}>
                          <p style={{ color: dataDetail?.is_fail ? 'red' : '' }}>
                            <span className='view-pc'>Ấn vào đây để</span> tạo lại đơn
                          </p>
                        </Button>
                      ) : (
                        <Button
                          icon={<CloudDownloadOutlined />}
                          onClick={() => window.open(`/in-don-hang/${dataDetail?.id}`, '_blank')}
                        >
                          <p>
                            <span className='view-pc'>Ấn vào đây để</span> tải đơn vận
                          </p>
                        </Button>
                      )}
                    </p>
                  </div>
                </>
              ) : (
                <div style={{ marginTop: 10 }}>
                  <Skeleton active />
                </div>
              )}
            </FormWrapper>
          </Col>
          <Col span={24}>
            <InfoUser
              info={dataDetail?.sender}
              note={dataDetail?.sender?.note}
              title='Thông tin người gửi hàng'
              loading={loadingDataDetail}
            />
          </Col>
          <Col span={24}>
            <InfoUser
              info={dataDetail?.receiver}
              note={
                <>
                  Sản phẩm: {dataDetail?.detail?.title} <br /> Shop:{' '}
                  {dataDetail?.detail?.view_product === 1 ? 'Cho' : 'Không cho'} khách xem hàng
                </>
              }
              title='Thông tin người nhận hàng'
              loading={loadingDataDetail}
            />
          </Col>
          {dataDetail?.statusLogs.length > 0 && (
            <Col span={24}>
              <FormWrapper title='Lịch sử vận chuyển' icon={<EnvironmentFilled />}>
                {!loadingDataDetail ? (
                  <>
                    {dataDetail?.statusLogs?.map((item) => (
                      <div className='box-info' key={item?.id}>
                        <p>{item?.created_at}</p>
                        <p>
                          {item?.status_string} {item?.description && ` - ${item?.description}`}
                        </p>
                      </div>
                    ))}
                  </>
                ) : (
                  <div style={{ marginTop: 10 }}>
                    <Skeleton active />
                  </div>
                )}
              </FormWrapper>
            </Col>
          )}
          {!loadingDataDetail && (
            <Col span={24}>
              <ButtonGroups>
                <Button size='large' onClick={() => history.goBack()} icon={<RollbackOutlined />}>
                  Trở về
                </Button>

                {!dataDetail?.is_cancel && (
                  <>
                    {dataDetail?.can_update && (
                      <Button size='large' onClick={() => setModalUpdateOrder(true)}>
                        Cập nhật
                      </Button>
                    )}
                    <Button size='large' onClick={() => setModalCallSupport(true)}>
                      Báo hỗ trợ
                    </Button>
                  </>
                )}

                {dataDetail?.can_cancel && (
                  <Button size='large' onClick={() => setModalCancelOrder(true)} loading={loading}>
                    Huỷ đơn
                  </Button>
                )}

                {dataDetail?.must_proccess && (
                  <>
                    <Button
                      size='large'
                      onClick={() =>
                        setModalUpdateStatus({
                          toggle: true,
                          type: 3,
                        })
                      }
                      loading={loading}
                    >
                      Tiếp tục giao hàng
                    </Button>
                    <Button
                      size='large'
                      onClick={() =>
                        setModalUpdateStatus({
                          toggle: true,
                          type: 2,
                        })
                      }
                      loading={loading}
                    >
                      Hoàn hàng
                    </Button>
                  </>
                )}
              </ButtonGroups>
            </Col>
          )}
        </Row>
      </WrapperPage>

      <ModalUpdateOrder
        open={modalUpdateOrder}
        onCancel={() => setModalUpdateOrder(false)}
        editData={dataDetail}
        getDetailOrder={getDetailOrder}
      />
      <ModalCancelOrder
        open={modalCancelOrder}
        onCancel={() => {
          if (!loading) {
            setModalCancelOrder(false)
          }
        }}
        onSubmit={() => {
          setLoading(true)
          orderService.cancel(
            {
              orderId: dataDetail?.id,
            },
            (response) => {
              toast.success(response?.data?.message, {
                autoClose: 1000,
              })
              setModalCancelOrder(false)
              getDetailOrder()
              setLoading(false)
              history.push(ROUTER.orderTracking)
            },
            (response) => {
              toast.error(response?.message)
              setLoading(false)
              setModalCancelOrder(false)
            }
          )
        }}
        loading={loading}
      />
      <ModalCallSupport
        open={modalCallSupport}
        onCancel={() => {
          setModalCallSupport(false)
        }}
        loading={loadingCallSupport}
        setLoading={setLoadingCallSupport}
        orderId={dataDetail?.id}
      />
      <ModalUpdateStatus
        modalUpdateStatus={modalUpdateStatus}
        onCancel={() => {
          setModalUpdateStatus({
            toggle: false,
          })
        }}
        loading={loadingUpdateStatus}
        setLoading={setLoadingUpdateStatus}
        orderId={dataDetail?.id}
      />
    </>
  )
}

export default Page