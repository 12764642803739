import TableBase from 'components/Table/TableBase'
import { numberWithCommas } from 'utils'
import { Link } from 'react-router-dom'

const HistoryTable = ({ data, onPagination }) => {
  const columns = [
    {
      title: 'Tài khoản',
      dataIndex: 'customer',
      render: (value) => value?.id,
    },
    {
      title: 'Ký quỹ hiện tại',
      dataIndex: 'escrow_before',
      render: (value) => numberWithCommas(value),
    },
    {
      title: 'Số dư',
      dataIndex: 'balance',
      render: (value) => numberWithCommas(value),
    },
    {
      title: 'Tổng đơn',
      dataIndex: 'count_order',
      render: (value) => value,
    },
    {
      title: 'Thời gian đối soát',
      dataIndex: 'created_at',
      render: (value) => value,
    },
    {
      title: 'Trạng thái thanh toán',
      dataIndex: 'is_escrow_string',
      render: (value) => value,
    },
    {
      title: 'Thao tác',
      render: (record) => (
        <div>
          {record?.id && (
            <Link to={`/bao-cao-tai-chinh?reconcilation_id=${record?.id}`}>Chi tiết</Link>
          )}
        </div>
      ),
    },
  ]

  return (
    <TableBase
      columns={columns}
      dataSource={data?.reconcilations}
      pagination={{
        total: data?.meta?.total,
        onChange: onPagination,
        current: data?.meta?.current_page,
        pageSizeOptions: ['10', '30', '50'],
        showTotal: (total) => `Tổng ${total}`,
      }}
      scroll={{ x: 1000 }}
      rowKey={(row) => row?.id}
      loading={!data}
    />
  )
}

export default HistoryTable
