import FormWrapper from 'components/Form/FormWrapper'
import { useCallback, useEffect, useState } from 'react'
import { financialService } from 'services'
import styled from 'styled-components'
import FilterForm from './components/FilterForm'
import HistoryTable from './components/HistoryTable'
import moment from 'moment'
import { Col, Row } from 'antd'
import useGetCustomerData from 'state/customer/hooks/useGetCustomerData'
import { numberWithCommas } from 'utils'
import { useLocation } from 'react-router-dom'
import * as qs from 'query-string'
import { log } from 'console'

const daysOfWeek = [
  { name: '2', key: 'monday' },
  { name: '3', key: 'tuesday' },
  { name: '4', key: 'wednesday' },
  { name: '5', key: 'thursday' },
  { name: '6', key: 'friday' },
]

const Page = () => {
  const { customerProfile } = useGetCustomerData()
  const location = useLocation()
  const { reconcilation_id } = qs.parse(location.search)

  const [paramGetReport, setParamGetReport] = useState<any>()
  const [dataList, setDataList] = useState<any>()
  const [loadingList, setLoadingList] = useState<boolean>(true)
  const [loadingExport, setLoadingExport] = useState<boolean>(false)

  useEffect(() => {
    if (reconcilation_id) {
      setParamGetReport({
        page: 1,
        per_page: 10,
        status: 0,
        payment_status: 0,
        reconcilation_status: 0,
        insurance_status: 0,
        reconcilation_id: reconcilation_id,
      })
    } else {
      setParamGetReport({
        page: 1,
        per_page: 10,
        status: 0,
        payment_status: 0,
        reconcilation_status: 0,
        insurance_status: 0,
        search_by: 'created_at',
        from_date: moment().subtract(7, 'days')?.format('YYYY-MM-DD'),
        to_date: moment()?.format('YYYY-MM-DD'),
      })
    }
  }, [reconcilation_id])

  const searchFinancial = useCallback(() => {
    if (paramGetReport) {
      setLoadingList(true)
      financialService.getReport(paramGetReport, (data) => {
        setDataList(data)
        setLoadingList(false)
      })
    }
  }, [paramGetReport])

  const exportFinancial = useCallback(() => {
    setLoadingExport(true)
    financialService.exportReport(paramGetReport, (response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `bao-cao-tai-chinh-${moment().unix()}.xlsx`)
      document.body.appendChild(link)
      link.click()
      setLoadingExport(false)
    })
  }, [paramGetReport])

  useEffect(() => {
    searchFinancial()
  }, [searchFinancial])

  return (
    <WrapperPage>
      <p className='title-page'>Báo cáo tài chính</p>

      <FormWrapper>
        <BoxInfo>
          <Row>
            <Col span={24} lg={{ span: 12 }}>
              <div>
                <p>Tài khoản: {customerProfile?.full_name}</p>
                <p>
                  Số dư tài chính:{' '}
                  <b style={{ display: 'contents' }}>
                    {customerProfile?.marginBalance?.margin_balance
                      ? `${numberWithCommas(customerProfile?.marginBalance?.margin_balance)}đ`
                      : 0}
                  </b>
                </p>
                <p>
                  Số dư ký quỹ:{' '}
                  <b style={{ display: 'contents' }}>
                    {customerProfile?.marginBalance?.margin_balance_available
                      ? `${numberWithCommas(customerProfile?.marginBalance?.margin_balance_available)}đ`
                      : 0}
                  </b>
                </p>
                <p>
                  Đối soát thứ{' '}
                  <b style={{ display: 'contents' }}>
                    {daysOfWeek
                      .filter((day) => customerProfile?.marginBalance[day.key])
                      .map((day) => day.name)
                      .join('/')}
                  </b>{' '}
                  hàng tuần - Phí chuyển khoản 5000đ/giao dịch - Chuyển khoản thường
                </p>
              </div>
            </Col>
            <Col span={24} lg={{ span: 12 }}>
              <div className='box-right'>
                <p>Ngân hàng: {customerProfile?.bankInfo?.bank?.title}</p>
                <p>Số tài khoản: {customerProfile?.bankInfo?.bank_number}</p>
                <p>Tên tài khoản: {customerProfile?.bankInfo?.bank_name}</p>
              </div>
            </Col>
          </Row>
        </BoxInfo>

        <FilterForm
          setParams={setParamGetReport}
          exportFinancial={exportFinancial}
          loadingExport={loadingExport}
          reconcilation_id={reconcilation_id}
        />

        <HistoryTable dataList={dataList} setParams={setParamGetReport} loadingList={loadingList} />
      </FormWrapper>
    </WrapperPage>
  )
}

export default Page

const BoxInfo = styled.div`
  margin-bottom: 12px;

  p {
    font-size: 13px;
    margin-bottom: 3px;
  }

  .box-right {
    ${({ theme }) => theme.mediaQueries.lg} {
      text-align: right;
    }
  }
`

const WrapperPage = styled.div`
  min-height: 77vh;
  width: 100%;
  padding: 0 20px 40px;
`
