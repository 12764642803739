import FormWrapper from 'components/Form/FormWrapper'
import styled from 'styled-components'
import HistoryTable from './components/HistoryTable'
import { useReconcilationHistory } from 'services/swr/reconcilationHistory'
import { useState } from 'react'

const WrapperPage = styled.div`
  min-height: 77vh;
  width: 100%;
  padding: 0 20px 40px;
`

const Page = () => {
  const [params, setParams] = useState({
    page: 1,
    per_page: 10,
  })

  const { data } = useReconcilationHistory(params)
  const handleChangePanation = (page: number, per_page: number) => setParams({ page, per_page })

  return (
    <WrapperPage>
      <p className='title-page'>Lịch sử đối soát</p>

      <FormWrapper>
        <HistoryTable data={data} onPagination={handleChangePanation} />
      </FormWrapper>
    </WrapperPage>
  )
}

export default Page
