import { API_BASE_URL, StatusCode } from 'constants/index'
import fetchHelper from 'helpers/FetchHelper'
import useSWR from 'swr'
import SWR_KEY from './constant'
import * as qs from 'query-string'

export const useReconcilationHistory = (params: { page: number; per_page: number }, options = {}) => {
  const { page, per_page } = params
  const formatParams = qs.stringify(params)

  const getData = async () =>
    await fetchHelper
      .fetch(`${API_BASE_URL}${SWR_KEY.RECONCILATION_HISTORY}?${formatParams}`, {
        method: 'GET',
      })
      .then(([res, status]: any) => {
        if (status === StatusCode.OK) return res?.data
        return undefined
      })

  return useSWR([SWR_KEY.RECONCILATION_HISTORY, page, per_page], getData, {
    revalidateOnFocus: false,
    ...options,
  })
}
